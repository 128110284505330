import moment from "moment/moment";
import "owl.carousel"

jQuery(document).ready(function ($) {

  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $("#mobile-menu").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('#mobile-menu').hasClass('active')) {
      $("#mobile-menu").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })


  // Initialize countdown HTML structure with jQuery
  $('.countdown').html(
    '<div class="time-block"><span class="days10">0</span><span class="days1">0</span><span class="label">d</span></div>' +
    '<div class="divider"></div>' +
    '<div class="time-block"><span class="hours10">0</span><span class="hours1">0</span><span class="label">t</span></div>' +
    '<div class="divider"></div>' +
    '<div class="time-block"><span class="minutes10">0</span><span class="minutes1">0</span><span class="label">min</span></div>' +
    '<div class="divider"></div>' +
    '<div class="time-block"><span class="seconds10">0</span><span class="seconds1">0</span><span class="label">sek</span></div>'
  );


  // Countdown setup - Always target the next Christmas
  function getNextChristmas() {
    var now = moment();
    var currentYear = now.year();
    var xmas = moment(`${currentYear}-12-25 00:00 -0500`, "YYYY-MM-DD HH:mm z");

    // If today is past Christmas, set next year's Christmas
    if (now.isAfter(xmas)) {
      xmas.add(1, 'year');
    }

    return xmas;
  }

  var countDownDate = getNextChristmas()

  // Update the countdown every second
  var x = setInterval(function () {
    var now = moment();
    var distance = countDownDate.diff(now);

    // Time calculations for days, hours, minutes, and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // Display the result in the respective elements
    $(".days10").text(Math.floor(days / 10));
    $(".days1").text(days % 10);
    $(".hours10").text(Math.floor(hours / 10));
    $(".hours1").text(hours % 10);
    $(".minutes10").text(Math.floor(minutes / 10));
    $(".minutes1").text(minutes % 10);
    $(".seconds10").text(Math.floor(seconds / 10));
    $(".seconds1").text(seconds % 10);

    // If the countdown is finished hide the countdown and show a message
    if (distance < 0) {
      clearInterval(x);
      $('.countdown').hide();
      // Assuming you want to change the content of a .preorder element if the countdown finishes
      // $(".preorder").text("Listen Now");
    }
  }, 1000);


  // CAROUSEL LOGIC
  function createHeroCarousel() {
    const heroCarousel = $("#hero-carousel")

    heroCarousel.owlCarousel({
      loop: true,
      nav: false,
      dots: true,
      items: 1,
      // startPosition: 0,
      autoplay: true,
      autoplayTimeout: 6000,
      autoplaySpeed: 1000,
      autoplayHoverPause: true,
      center: true,
      checkVisible: false,
    })
  }
  createHeroCarousel()


  // SINGLE SCROLL CAROUSEL
  function createSingleScrollCarousel() {
    const singleScrollCarousel = $(".single-horizontal-scroll")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    singleScrollCarousel.owlCarousel({
      loop: false,
      nav: false,
      dots: false,
      autoplay: false,
      autoplaySpeed: 500,
      startPosition: 0,
      margin: 20,
      items: 1,
      // stagePadding: 50,
      center: false,
      responsive: {
        0: {
        },
        992: {
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createSingleScrollCarousel()

  // SCROLL CAROUSEL
  function createScrollCarousel() {
    const scrollCarousel = $(".horizontal-scroll")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    scrollCarousel.owlCarousel({
      nav: false,
      dots: false,
      autoplay: false,
      autoplaySpeed: 500,
      startPosition: 0,
      margin: 20,
      center: false,
      responsive: {
        0: {
          loop: true,
          items: 1.2,
        },
        992: {
          loop: false,
          items: 3,
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createScrollCarousel()

  // SCROLL CAROUSEL
  function createInfinityScrollCarousel() {
    const infiniteScrollCarousel = $(".infinity-horizontal-scroll")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    infiniteScrollCarousel.owlCarousel({
      nav: true,
      dots: false,
      autoplay: false,
      autoplaySpeed: 500,
      startPosition: 0,
      margin: 30,
      center: false,
      responsive: {
        0: {
          loop: true,
          items: 1.2,
        },
        992: {
          loop: false,
          items: 3,
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createInfinityScrollCarousel()

  // SNOW
  let snowHtml = '';
  for (let i = 0; i < 500; i++) {
    snowHtml += `<div class="snow"></div>`;
  }

  $('.snow-bg').html(snowHtml);


  $('#startswithletter input').on('change', function () {
    $('#filters').trigger('submit');
  })

  $('#genre input').on('change', function () {
    $('#genre-filters').trigger('submit');
  })

  $('#s').blur(function () {
    $(this).val('');
  });


  // WP IFRAME
  $('.wp-block-embed').before('<div class="clearfix"></div>');


});